<template>
    <div id="m_index">
        <div class="top d_flex">
            <div class="logo"><img :src="$store.state.tenantInfo.logo | fullPath" alt=""></div>
            <div class="right_content d_flex">
                <template v-if="!isLogin">
                    <span class="login" @click="$router.push('/m_login')">登录</span>
                    <span class="registered" @click="$router.push('/m_register')">注册</span>
                </template>
                <div class="user" v-else><img src="@/assets/img/mobile/10.png" alt=""></div>
                <span @click="show = !show"><img src="@/assets/img/mobile/nav_bar.png" alt=""></span>
            </div>
        </div>
        <div class="bg_img">
            <el-carousel height="86px">
                <el-carousel-item v-for="item in advertList" >
                    <div class="user_adv" v-if="item.image"  @click="toUrl(item.url)">
                        <img :src="item.image | fullPath" alt="">
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="activity">
            <div><img src="@/assets/img/mobile/01.png" alt=""></div>
            <div><img src="@/assets/img/mobile/02.png" alt=""></div>
            <div><img src="@/assets/img/mobile/03.png" alt=""></div>
            <div><img src="@/assets/img/mobile/04.png" alt=""></div>
        </div>
        <div class="activity_title">热销推荐</div>
        <div class="good_list">
            <div class="item"><img src="@/assets/img/05-1.png" alt=""></div>
            <div class="item" v-for="item in hotgoodsList" @click="buyGoods(item)">
                <div class="img_wrap"><img :src="item.cover | fullPath" alt=""></div>
                <div class="title">{{item.name}}</div>
                <div class="other clearfix">
                    <span class="price fl">￥{{(item.cost+item.profit).toFixed(2)}}</span>
                    <span class="wei fr">{{item.weight}} kg/件</span>
                </div>
                <div class="btn">立即购买</div>
            </div>
        </div>
        <div class="activity_title">新品上架</div>
        <div class="good_list">
            <div class="item"><img src="@/assets/img/05-1.png" alt=""></div>
            <div class="item" v-for="item in newestGoodsList" @click="buyGoods(item)">
                <div class="img_wrap"><img :src="item.cover | fullPath" alt=""></div>
                <div class="title">{{item.name}}</div>
                <div class="other clearfix">
                    <span class="price fl">￥{{(item.cost+item.profit).toFixed(2)}}</span>
                    <span class="wei fr">{{item.weight}} kg/件</span>
                </div>
                <div class="btn">立即购买</div>
            </div>
        </div>
        <div class="section4">
            <span>新闻动态</span> 
            <div class="article_list">
                <div class="article_item" v-for="item in articleList2" @click="$router.push('/news/'+item.id+'?type=2')">
                    <div class="title">
                        <a>{{item.title}}</a>
                        <p>{{$time.getDate(item.createTime)}}</p>
                    </div>
                    <div class="summary">{{item.summary}}</div>
                </div>
                <!-- <div class="article_item">
                    <div class="title">
                        <a>快递广告优势</a>
                        <p>2020-06-15</p>
                    </div>
                    <div class="summary">在快递单上做广告保证了每份广告至少有两人会仔细阅读，比传统的传单广告、平面广告更有效率。这样每份广告传达至两人手中，每份广告都有人浏览，不浪费。</div>
                </div>
                <div class="article_item">
                    <div class="title">
                        <a>什么是快递单广告传媒</a>
                        <p>2020-06-15</p>
                    </div>
                    <div class="summary">快递公司在全国各个大中小城市都有网点，广告的范围广，受众范围也广。一份快递纸媒在使用过程中，至少有5个人会接触到，比如收件人、收件单位的代收人、身边的同事等等，最终可能达到10 几个人甚至更多...</div>
                </div>
                <div class="article_item">
                    <div class="title">
                        <a>快递单广告的特点</a>
                        <p>2020-06-15</p>
                    </div>
                    <div class="summary">温柔的强迫性：快递单广告媒体上的广告会让客户在不知不觉中看到，不会让客户产生反感，让商家的广告信息自然的渗透进去。针对性：现在使用快递的大多数是白领、大学生等人群，所以快递商业联盟的广告通过快递的方式</div>
                </div>
                <div class="article_item">
                    <div class="title">
                        <a>小家电如何通过电商大数据精准引流客户？</a>
                        <p>2020-01-17</p>
                    </div>
                    <div class="summary">据《中国小家电行业产销需求与投资预测分析报告》统计数据显示，2020年中国小家电行业市场规模将突破4000亿元，未来五年(2020-2024)年均复合增长率约为12.63%，并预测...</div>
                </div>
                <div class="article_item">
                    <div class="title">
                        <a>【快递+广告】赚钱营销新思路</a>
                        <p>2020-01-17</p>
                    </div>
                    <div class="summary">快递业已经很发达了，中国的电子商务使得物流行业已经飞速发展，每天都有很多很多的快递从不同的地方发出，飞向不同的地方，这也就造就了一个新的趋势...</div>
                </div> -->
            </div>
        </div>
        <div class="bottom_content d_flex">
            <div class="b_item">
                <div class="d_flex">
                    <img src="@/assets/img/bottom1.png" alt="">
                    <p>
                        <span>降低成本</span><br>
                        <span>帮你降低50%发货成本</span>
                    </p>
                </div>
                <div class="d_flex">
                    <img src="@/assets/img/bottom2.png" alt="">
                    <p>
                        <span>保证品质</span><br>
                        <span>注重品质，物廉价美</span>
                    </p>
                </div>
            </div>
            <div class="b_item">
                <div class="d_flex">
                    <img src="@/assets/img/bottom4.png" alt="">
                    <p>
                        <span>去除库存</span><br>
                        <span>回收库存、去礼品库</span>
                    </p>
                </div>
                <div class="d_flex">
                    <img src="@/assets/img/bottom3.png" alt="">
                    <p>
                        <span>多种选择</span><br>
                        <span>低价礼品、配重礼品、高端礼品</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="friend_link">
            <span>友情链接：</span>
            <a :href="item.url" v-for="item in linksList" target="_blank">{{item.name}}</a>
        </div>
        <div class="copyright" v-if="$store.state.tenantInfo.beianhao">
            <img src="@/assets/img/guohui.png" alt="">
            {{$store.state.tenantInfo.beianhao}}
        </div>
        <transition name="fade">
            <div class="mask" v-if="show" @click="show=false">
            </div>
        </transition>
        <transition name="fade1">
            <div class="mask_left" v-if="show">
                <div @click="$router.push('/m_index')">首页</div>
                <div @click="$router.push('/m_goodsall')">礼品商城</div>
                <div @click="$router.push('/member/index')">会员中心</div>
                <div @click="$router.push('/member/vip')">收费标准</div>
                <div @click="$router.push('/member/guide')">代发指南</div>
                <!-- <div v-if="$store.state.showOtherSite" @click="$router.push('/member/other_site')">源码搭建</div> -->
                <div @click="openQQ">在线客服</div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            isLogin:false,
            show:false,
            hotgoodsList:[],
            newestGoodsList:[],
            articleList2:[],
            advertList:[],
        }
    },
    created(){
        let token = localStorage.getItem('dftToken')
        if(token){
            this.isLogin = true
        }else{
            this.isLogin = false
        }
        this.getAdvertList();
        this.getArticle2();
        this.getHotGoodsList();
        // this.IsPC();
    },
    mounted(){},
    watch: {},
    methods: {
        //新闻列表
        getArticle2(){
            this.$request.post({
                url:'/bus/article/public/list',
                params:{
                    pageNo: 1,
                    pageSize: 6,
                    type: 2
                },
                success: res => {
                    this.articleList2 = res.list;
                }
            })
        },
        openQQ() {
            var url = 'tencent://message/?uin=' + this.$store.state.tenantInfo.qq + '&amp;Site=&amp;Menu=yes';
            window.open(url,'_self')
        },
        //热销商品列表
        getHotGoodsList(){
            this.$request.post({
                url:'/bus/goodsInfo/public/list',
                params:{
                    pageNo: 1,
                    pageSize: 11,
                    sort:3,
                },
                success: res => {
                    this.hotgoodsList = res.list;
                    if(this.hotgoodsList.length < 11){
                        this.hotNull = 11 - this.hotgoodsList.length
                    }
                    this.getNewestGoodsList();
                }
            })
        },
        //最新商品
        getNewestGoodsList(){
            this.$request.post({
                url:'/bus/goodsInfo/public/list',
                params:{
                    pageNo: 1,
                    pageSize: 11,
                    sort:5,
                },
                success: res => {
                    this.newestGoodsList = res.list;
                    if(this.newestGoodsList.length < 11){
                        this.newNull = 11 - this.newestGoodsList.length
                    }
                }
            })
        },
        IsPC(){  
            var userAgentInfo = navigator.userAgent;
            var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
            var flag = true;  
            for (var v = 0; v < Agents.length; v++) {  
                if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
            }  
            if(flag){
                this.$router.push('/index')
            }
        },
        getAdvertList(){
            this.$request.post({
                url:'/ReportApi/business/advert',
                params:{
                    pageNo: 1,
                    pageSize: 1,
                    type: 1,
                    tenantId: localStorage.getItem("tenantInfoid")
                },
                success: res => {
                    // if(res.length>0){
                    //     this.advert = res[0];
                    // }
                    this.advertList = res;
                }
            })
        },
        toUrl(url){
            if(url.indexOf('http')!=-1){
                window.location.href = url;
            }else{
                this.$router.push(url);
            }
        },
        buyGoods(item){
            this.$store.commit('getGoodsInfo',item)
            this.$router.push('/member/send_order');
        },

    }
}
</script>

<style lang="scss" scoped>
#m_index{
    .user_adv{
        cursor: pointer;
        margin-bottom: 10px;
        img{
            width: 1200px;
        }
        height: 100%;
    }
    padding-top: 44px;
    .top{
        position: fixed;
        top: 0;
        background: #ffffff;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        padding: 0 14px;
        height: 44px;
        z-index: 10;
        .logo{
            align-items: center;
            width: 57.6px;
            img{
                width: 100%;
                display: block;
            }
        }
        .right_content{
            align-items: center;
            span{
                &:last-child{
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    img{
                        width: 100%;
                    }
                }
            }
            .login{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                background: #FF8800;
                border-radius: 12px;
                padding: 2px 10px;
            }
            .registered{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FF8800;
                padding: 2px 10px;
                border: 1px solid #ff8800;
                border-radius: 12px;
                margin: 0 14px 0 5px;
            }
            .user {
                width: 18px;
                height: 18px;
                display: block;
                margin-right: 18px;
                img{
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }
    }
    .bg_img{
        img{
            width:100%;
            display: block;
        }
    }
    .activity{
        padding:12px 14px 0;
        font-size: 0;
        div{
            display: inline-block;
            width: 49%;
            &:nth-child(even){
                margin-left: 2%;
            }
            &:nth-child(n + 3){
                margin-top: 8px;
            }
            img{
                width: 100%;
            }
        }
    }
    .activity_title{
        color: #000;
        font-size: 14px;
        font-weight: bold;
        margin-top: 12px;
        margin-left: 14px;
        padding-left: 5px;
        border-left: 4px solid #FF8800;
    }
    .good_list{
        font-size: 0;
        padding:4px 14px 0;
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 49%;
            display: inline-block;
            border: 1px solid #eee;
            box-sizing: border-box;
            vertical-align: top;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &:nth-child(even){
                margin-left: 2%;
            }
            &:nth-child(n + 3){
                margin-top: 8px;
            }
            .img_wrap{
                height: calc(49vw - 14px);
            }
            .title{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 10px;
                color: #999;
                line-height: 22px;
                padding: 0 5px;
            }
            .other{
                padding: 0 5px;
                .price{
                    font-size: 14px;
                    color: #f80;
                }
                .wei{
                    font-size: 11px;
                    color: #666;
                }
            }
            .btn{
                line-height: 28px;
                text-align: center;
                color: #f80;
                border: 1px solid #f80;
                box-sizing: border-box;
                border-radius: 4px;
                margin: 6px 9px 2% 9px;
                display: block;
                height: 28px;
                font-size: 12px;
                &:hover{
                    color: #fff;
                    background: #f80;
                }
            }
        }
    }
    .section4{
        position: relative;
        text-align: center;
        margin-bottom: 25px;
        background: #fff;
        &>span{
            position: relative;
            display: inline-block;
            margin: 25px auto;
            font-weight: 700;
            font-size: 22px;
            &::after{
                content: "";
                position: absolute;
                bottom: -10px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                display: inline-block;
                background: -webkit-gradient(linear,left top,right top,from(rgba(255,166,0,.929412)),to(#ff6a00));
                background: linear-gradient(90deg,rgba(255,166,0,.929412),#ff6a00);
                width: 40%;
                border-radius: 50px;
                height: 4px;
            }
        }
        .article_list{
            text-align: left;
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            flex-wrap: wrap;
            .article_item{
                border: 1px solid #eee;
                padding: 10px 12px;
                width: 96%;
                box-sizing: border-box;
                margin:10px auto;
                cursor: pointer;
                &:hover{
                    box-shadow: 0 0 5px 1px #dcdfe6;
                    .title a{
                        color: #ff6a00;
                    }
                }
                .title{
                    display: -webkit-box;
                    display: flex;
                    align-items: center;
                    -webkit-box-pack: justify;
                    justify-content: space-between;
                    margin-bottom: 10px;
                }
                .summary{
                    color: #767676;
                    font-size: 14px;
                }
                .title a{
                    font-size: 18px;
                    color: #333;
                    flex: 1;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-right: 10px;
                }
                .title{
                    p{
                        color: #999;
                    }           
                }
            }
        }
    }
    .bottom_content{
        padding: 0 14px;
        margin-top: 10px;
        .b_item{
            flex: 1;
            div{
                img{
                    width: 74px;
                }
                p{
                    margin-top: 15px;
                    span{
                        &:first-child{
                            color: #000000;
                            font-size: 14px;
                            font-weight: bold;
                        }
                        &:last-child{
                            font-size: 10px;
                            color: #999;
                        }
                    }
                }
            }
        }
    }
    .friend_link{
        margin-top: 15px;
        text-align: center;
        span{
            font-size: 10px;
            color: #333333;
            &:first-child{
                color: #666666;
            }
        }
        a{
            margin-right: 5px;
            font-size: 10px;
        }
    }
    .copyright{
        text-align: center;
        font-size: 8px;
        color: #333333;
        margin-top: 9px;
        padding-bottom: 42px;
        img{
            height: 16px;
        }
    }
    .mask{
        position: fixed;
        top: 44px;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.5);
        z-index: 11;
    }
    .mask_left{
        width: 35%;
        text-align: center;
        position: fixed;
        top: 44px;
        background: #fff;
        bottom: 0;
        right: 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        z-index: 11;
        div{
            padding: 9px 0;
            border-bottom: 1px solid #eee;
        }
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade1-enter, .fade1-leave-to {
        transform: translateX(100%);
    }
    .fade1-enter-active, .fade1-leave-active {
        transition: all .5s;
    }
}
</style>